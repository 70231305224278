







import { Vue, Component } from 'vue-property-decorator';
import { FrontE } from '@/sdk/common/SysConst';
@Component
export default class InsBuilding extends Vue {
    private img:string = '';
    private background:string = '';
    private paddingTop:string = '';
    beforeCreate () {
      if (window.location.pathname === '/admin') {
        this.$router.push('admin');
      } else if (((FrontE.pcBuilding && !this.$store.state.isMobile) || (FrontE.mobileBuilding && this.$store.state.isMobile)) && this.$route.name !== 'building') {
        this.$store.dispatch('setOrgPath', this.$route.path);
        this.$router.push('/building');
      }
    }
    created () {
      if ((this.isMobile && !FrontE.mobileBuilding) || (!this.isMobile && !FrontE.pcBuilding)) return;
      this.background = this.isMobile ? '/static/building/Mobile.jpg' : '/static/building/PC.jpg';
      this.img = this.isMobile ? '/static/building/MobileLogo.png' : '/static/building/PCLogo.png';

      this.$HiddenLayer();
    }
    mounted () {
    }
    data () {
      return {
      };
    }

    get isMobile () {
      return this.$store.state.isMobile;
    }

    get indexLayout () {
      return '';
    }
}
